import { Model } from '@cloudfun/core'
import { main, Store as MainStore, State as MainState } from './main'
import configuration from './configuration'
import role from './role'
import user from './user'
import actionLog from './action-log'
import color from './color'
import member from './member'
import order from './order'
import pictureAlbum from './picture-album'
import picture from './picture'
import product from './product'
import productItem from './product-item'
import productItemSize from './product-item-size'
import size from './size'
import template from './template'

const model = new Model('YonexClothing', {
  main,
  configuration,
  role,
  user,
  actionLog,
  color,
  member,
  picture,
  pictureAlbum,
  product,
  productItem,
  productItemSize,
  order,
  size,
  template
})

const backendServer = process.env.VUE_APP_BACKEND_URL
model.clients.authorized = model.createHttpClient(`${backendServer}/api`, true)
model.clients.unauthorized = model.createHttpClient(`${backendServer}/api`)
model.onLogin = (data) => model.clients.unauthorized.post('System/Login', {
  account: data.account,
  password: data.password,
  captchaToken: data.captchaToken,
  captcha: data.captcha
})
model.onLogout = () => model.clients.authorized.post('System/Logout')
model.onReloadUser = () => model.clients.authorized.post('System/CurrentUser')
model.onReloadEnums = () => model.clients.unauthorized.get('System/Enumerations')
model.onLog = (message) => model.clients.unauthorized.post('System/Log', message)

export type State = { main: MainState };
export type Store = MainStore<Pick<State, 'main'>>;

export default model
