import { ISitemapNode } from "@cloudfun/core";

const sitemap: ISitemapNode = {
  icon: "HomeIcon",
  to: "",
  title: "Home",
  subNodes: [
    {
      icon: "HomeIcon",
      to: "dashboard",
      title: "儀錶板"
    },
    // {
    //   icon: 'fa-columns',
    //   title: '選單安排',
    //   subNodes: [
    //     {
    //       icon: '',
    //       to: '/dashboard',
    //       title: '側邊選單',
    //       ignore: true
    //     },
    //     {
    //       icon: '',
    //       to: '/simple-menu/dashboard',
    //       title: '簡易選單',
    //       ignore: true
    //     },
    //     {
    //       icon: '',
    //       to: '/top-menu/dashboard',
    //       title: '上方選單',
    //       ignore: true
    //     }
    //   ]
    // },
    {
      icon: "SettingsIcon",
      to: "configuration",
      title: "系統配置"
    },
    {
      icon: "UserIcon",
      to: "user",
      title: "用戶管理",
      subNodes: [
        {
          icon: "fa-magic",
          to: "user-wizard",
          title: "用戶註冊精靈"
        },
        {
          icon: "UsersIcon",
          to: "role",
          title: "角色管理"
        },
        {
          icon: "UserIcon",
          to: "user",
          title: "用戶管理"
        }
      ]
    },
    {
      icon: "ActivityIcon",
      to: "action-log",
      title: "操作紀錄"
    },
    {
      icon: "fa-archive",
      to: "order",
      title: "訂單管理"
    },
    {
      icon: "ShoppingBagIcon",
      to: "product",
      title: "產品管理",
      subNodes: [
        {
          icon: "PenToolIcon",
          to: "color",
          title: "顏色資料"
        },
        {
          icon: "ShoppingBagIcon",
          to: "size",
          title: "尺寸資料"
        },
        {
          icon: "PackageIcon",
          to: "product",
          title: "產品資料"
        }
      ]
    },
    {
      icon: "fa-sticky-note",
      to: "/template/use-terms",
      title: "內文管理",
      subNodes: [
        {
          icon: "fa-pen",
          to: "/template/use-terms",
          title: "使用者條款"
        },
        {
          icon: "fa-pen",
          to: "/template/service-terms",
          title: "服務條款"
        },
        {
          icon: "fa-pen",
          to: "/template/faq",
          title: "FAQ"
        }
      ]
    }
  ]
};

export default sitemap;
