import { App } from 'vue'
import faker from './faker'
import helper from './helper'
import lodash from './lodash'
import formatDate from 'xe-utils/toDateString'

export const globalUtils = {
  formatDate
};

export default (app: App): void => {
  app.use(faker)
  app.use(helper)
  app.use(lodash)
}
