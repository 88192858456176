import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import ErrorPage from './views/error-page/Main.vue'

const viewRotes: RouteRecordRaw[] = [
  // project views
  { path: 'dashboard', component: () => import('./views/dashboard/Main.vue') },
  { path: 'configuration', component: () => import('./views/configuration/Main.vue') },
  { path: 'action-log', component: () => import('./views/action-log/Main.vue') },
  { path: 'role', component: () => import('./views/role/Main.vue') },
  { path: 'user', component: () => import('./views/user/Main.vue') },
  { path: 'user-wizard', component: () => import('./views/user-wizard/Main.vue') },
  // { path: 'category', component: () => import('./views/category/Main.vue') },
  // { path: 'banner', component: () => import('./views/banner/Main.vue') },
  // { path: 'album', component: () => import('./views/album/Main.vue') },
  { path: 'order', component: () => import('./views/order/Main.vue') },
  { path: 'color', component: () => import('./views/color/Main.vue') },
  { path: 'size', component: () => import('./views/size/Main.vue') },
  { path: 'product', component: () => import('./views/product/Main.vue') },
  { path: 'template/:number', component: () => import('./views/template/Main.vue') },
  // { path: 'attribute', component: () => import('./views/attribute/Main.vue') },
]

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: 'dashboard', children: viewRotes, component: () => import('./layouts/side-menu/Main.vue') },
  { path: '/simple-menu', redirect: 'dashboard', children: viewRotes, component: () => import('./layouts/simple-menu/Main.vue') },
  { path: '/top-menu', redirect: 'dashboard', children: viewRotes, component: () => import('./layouts/top-menu/Main.vue') },
  { path: '/login', name: 'login', component: () => import('./views/login/Main.vue') },
  { path: '/error-page', name: 'error-page', component: ErrorPage },
  { path: '/:pathMatch(.*)*', component: ErrorPage }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior (to, from, savedPosition) { return savedPosition || { left: 0, top: 0 } }
})
